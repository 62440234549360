import { uploadFiles } from './file-utils';

async function prepareJobPayload(formData, appId, ethAddress) {
  const fileIds = [];
  const kwargs = {};
  const meta = {};
  for (let [key, value] of Object.entries(formData)) {
    if (value.type === 'file') {
      const files = await uploadFiles([value.value]);
      fileIds.push(files[0].id);
      kwargs[value.kwarg] = files[0].id;
    } else if (value.kwarg) {
      kwargs[value.kwarg] = value.value;
    }
    // Set the job name
    if (value.type === undefined) {
      meta.name = value.value;
    }
  }
  meta.kwargs = kwargs;
  return {
    meta,
    appId,
    ethAddress,
    fileIds,
  };
}

export { prepareJobPayload };
