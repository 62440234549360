import { ALCHEMY_CONFIG } from './constants';
import { fromWei } from 'web3-utils';
import { Alchemy } from 'alchemy-sdk';

const alchemy = new Alchemy(ALCHEMY_CONFIG);

async function getEthBalance(account) {
  const balance = await alchemy.core.getBalance(account, 'latest');
  const balanceInteger = Number(fromWei(balance, 'ether')).toFixed(3);
  return balanceInteger;
}

// async function getInfraPrice() {
//   const response = await fetch(
//     'https://pro-api.coinmarketcap.com/v2/cryptocurrency/quotes/latest?id=1027&convert=USD'
//   );
//   const price = response.data.data['1027'].quote.USD.price;
// }

function getTruncatedWallet(address) {
  if (!address) {
    return '';
  }
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
}

export { getTruncatedWallet, getEthBalance };
