import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { toggleHeaderTransparent } from '../slices/interface';

function Network() {
  const dispatch = useDispatch();
  const wallet = useSelector(state => state.wallet);

  useEffect(() => {
    dispatch(toggleHeaderTransparent());
    return () => {
      dispatch(toggleHeaderTransparent());
    };
  });

  return (
    <div className="network-splash">
      <h1>INFRAX</h1>
      <h2 className="subheader">The Gateway to Crypto Native AI and Compute</h2>
      <div className="splash-info-row">
        <div className="splash-info-tile">
          <div className="splash-info-tile-text">
            <p>INFRA/USDT</p>
            <div className="value">
              <p>${wallet.infraPrice}</p>
            </div>
          </div>
        </div>
        {/* <div className="splash-info-tile">
          <div className="splash-info-tile-text">
            <p>ETH/USDT</p>
            <div className="value">
              <p>${Number(wallet.ethPrice).toFixed(2)}</p>
            </div>
          </div>
        </div> */}
      </div>
      <a
        href="https://dapp2.infrax.network/apps.html"
        className="infraxFancyButton"
      >
        Explore Apps
      </a>
    </div>
  );
}

export default Network;
