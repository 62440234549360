import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import { MetaMaskProvider } from '@metamask/sdk-react';
import store from './store';

import Apps from './pages/apps';
import AppForm from './pages/app-form';
import Devlog from './pages/devlog';
import NotFound from './pages/not-found';
import Jobs from './pages/jobs';
import Staking from './pages/staking';
import Profile from './pages/profile';
import Network from './pages/network';
import NetworkDetails from './pages/network-details';

import reportWebVitals from './reportWebVitals';

const sdkOptions = {
  extensionOnly: true,
  preferDesktop: true,
  dappMetadata: {
    name: 'INFRAX Network',
    description: 'INFRAX network',
    url: 'https://nodes.infrax.network', // origin must match your domain & subdomain
    icons: ['https://avatars.mywebsite.com/'],
  },
};

const router = createBrowserRouter([
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <Network />,
      },
      {
        path: 'network',
        element: <Network />,
      },
      {
        path: 'network/:id',
        element: <NetworkDetails />,
      },
      {
        path: 'jobs',
        children: [
          {
            index: true,
            element: <Jobs />,
          },
        ],
      },
      {
        path: 'apps',
        children: [
          {
            index: true,
            element: <Apps />,
          },
          {
            path: 'create-app',
            element: <AppForm />,
          },
          {
            path: 'edit-app/:id',
            element: <AppForm />,
          },
        ],
      },
      {
        path: 'devlog',
        element: <Devlog />,
      },
      {
        path: 'staking',
        element: <Staking />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('network-main'));
root.render(
  <MetaMaskProvider debug={true} sdkOptions={sdkOptions}>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </MetaMaskProvider>
);

reportWebVitals();
