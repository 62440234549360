import React from 'react';
import ConnectWalletButton from './connectWalletButton';

function ConnectWallet() {
  const title = 'Connect Wallet';
  const description = 'To get started, connect your wallet.';

  return (
    <div className="network-empty-state" style={{ marginBottom: `${5}rem` }}>
      <img src="/assets/icons/infrax_logo_no_text.svg" alt="" />
      <h2>{title}</h2>
      <p>{description}</p>
      <ConnectWalletButton classOverwrite="infrax-system-button-primary" />
    </div>
  );
}

export default ConnectWallet;
