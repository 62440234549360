import { Network } from 'alchemy-sdk';
import Web3 from 'web3';
import StakingContract from '../abi_staking.json';
import CoinContract from '../abi_coin.json';

const ALCHEMY_CONFIG = {
  apiKey: 'eKotgLmJXUvXSsgTK5infA0Lqif1q0mr',
  network: Network.ETH_MAINNET,
};

const IS_RELEASE = false;
const SERVER = 'https://api.infrax.network';
// const SERVER = 'http://0.0.0.0:8421';
const CONTRACT_ADDRESS = '0xe9eccde9d26fcbb5e93f536cfc4510a7f46274f8';
// const STAKING_ADDRESS = '';
const COIN_CONTRACT = '0xe9EccDE9d26FCBB5e93F536CFC4510A7f46274f8';
const STAKING_CONTRACT = '0x611B0906058744C2e8fd158A9FC7Afd2e8c30817';
const INFRAX_TEAM_ADDRESS = '0x403f6c78930c69bd21d2376220aa86133237c5eb';
const AUTH_PHRASE =
  "Welcome! Sign this message to login to the site. This doesn't cost you anything and is free of any gas fees.";

const WEB3_PROVIDER_RPC =
  'https://eth-mainnet.g.alchemy.com/v2/eKotgLmJXUvXSsgTK5infA0Lqif1q0mr';
const WEB3_PROVIDER = new Web3(
  new Web3.providers.HttpProvider(WEB3_PROVIDER_RPC)
);

const COIN_CONTRACT_ABI = new WEB3_PROVIDER.eth.Contract(
  CoinContract,
  COIN_CONTRACT
);

const STAKING_CONTRACT_ABI = new WEB3_PROVIDER.eth.Contract(
  StakingContract.abi,
  STAKING_CONTRACT
);

export {
  ALCHEMY_CONFIG,
  WEB3_PROVIDER,
  WEB3_PROVIDER_RPC,
  CONTRACT_ADDRESS,
  STAKING_CONTRACT,
  COIN_CONTRACT_ABI,
  COIN_CONTRACT,
  STAKING_CONTRACT_ABI,
  INFRAX_TEAM_ADDRESS,
  SERVER,
  IS_RELEASE,
  AUTH_PHRASE,
};
