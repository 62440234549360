import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CONTRACT_ADDRESS,
  ALCHEMY_CONFIG,
  COIN_CONTRACT_ABI,
  STAKING_CONTRACT_ABI,
} from '../utils/constants';
import { utils } from 'web3';
import axios from 'axios';

const fetchInfraBalance = createAsyncThunk(
  'wallet/fetchInfraBalance',
  async (_, { rejectWithValue, getState }) => {
    const state = getState();
    if (state.wallet.connected) {
      const account = state.wallet.connectedAccount;
      const baseURL = `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_CONFIG.apiKey}`;
      const data = JSON.stringify({
        jsonrpc: '2.0',
        method: 'alchemy_getTokenBalances',
        params: [`${account}`, [`${CONTRACT_ADDRESS}`]],
        id: 42,
      });

      const config = {
        method: 'post',
        url: baseURL,
        headers: {
          'Content-Type': 'application/json',
        },
        data,
      };

      axios(config)
        .then(function (response) {
          return Number(response.data.result.tokenBalances[0].tokenBalance);
        })
        .catch(function (error) {
          return rejectWithValue(error.message);
        });
    } else {
      return 0;
    }
  }
);

const fetchTestInfraBalance = createAsyncThunk(
  'staking/fetchTestInfraBalance',
  async (_, { rejectWithValue, getState }) => {
    const wallet = getState().wallet?.connectedAccount;
    if (wallet) {
      const balance = await COIN_CONTRACT_ABI.methods.balanceOf(wallet).call();
      return utils.fromWei(balance, 'gwei');
    } else {
      return rejectWithValue('Wallet not connected');
    }
  }
);

const fetchStakedInfra = createAsyncThunk(
  'staking/fetchStakedInfra',
  async (_, { rejectWithValue, getState }) => {
    const wallet = getState().wallet?.connectedAccount;
    if (wallet) {
      const stakedBalance = await STAKING_CONTRACT_ABI.methods
        .stakes(wallet)
        .call();
      if (stakedBalance.amount === 0n) {
        return { amount: 0, timestamp: 0 };
      }
      return {
        amount:
          Math.round(utils.fromWei(stakedBalance.amount, 'gwei') * 100) / 100,
        timestamp: Number(stakedBalance.timestamp),
      };
    } else {
      return rejectWithValue('Wallet not connected');
    }
  }
);

const fetchStakedRewards = createAsyncThunk(
  'staking/fetchStakedRewards',
  async (_, { rejectWithValue, getState }) => {
    const wallet = getState().wallet?.connectedAccount;
    if (wallet) {
      const currentTime = Math.floor(Date.now() / 1000);
      const stakedRewards = await STAKING_CONTRACT_ABI.methods
        .calculateRewards(wallet, currentTime.toString())
        .call();
      if (stakedRewards === 0n) {
        return 0;
      }
      return Number(utils.fromWei(stakedRewards, 'gwei'));
    } else {
      return rejectWithValue('Wallet not connected');
    }
  }
);

const checkHarvestStatus = createAsyncThunk(
  'staking/checkHarvestStatus',
  async (_, { rejectWithValue, getState }) => {
    const wallet = getState().wallet?.connectedAccount;
    if (wallet) {
      const stakeOlderThanOneCycle = await STAKING_CONTRACT_ABI.methods
        .stakeIsOlderThanOneCycle(wallet)
        .call();
      return stakeOlderThanOneCycle;
    } else {
      return rejectWithValue('Wallet not connected');
    }
  }
);

const checkEmergency = createAsyncThunk(
  'staking/checkEmergency',
  async (_, { rejectWithValue, getState }) => {
    const wallet = getState().wallet?.connectedAccount;
    if (wallet) {
      const emergency = await STAKING_CONTRACT_ABI.methods
        .isEmergency(wallet)
        .call();
      return emergency;
    } else {
      return rejectWithValue('Wallet not connected');
    }
  }
);

const StakingSlice = createSlice({
  name: 'staking',
  initialState: {
    error: null,
    canHarvestStake: false,
    canHarvestStakeStatus: 'idle',
    status: 'idle',
    isEmergency: false,
    isEmergencyStatus: 'idle',
    infraBalance: 0,
    infraBalanceStatus: 'idle',
    totalStakedInfra: 0,
    totalStakedInfraStatus: 'idle',
    totalStakedRewards: 0,
    totalStakedRewardsStatus: 'idle',
    totalStakedInfraTimestamp: 0,
  },
  reducers: {
    setTotalStakedInfra: (state, action) => {
      state.totalStakedInfra = action.payload;
    },
    resetStakeData: state => {
      state.infraBalance = 0;
      state.totalStakedInfra = 0;
      state.totalStakedRewards = 0;
      state.canHarvestStake = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchInfraBalance.pending, state => {
        state.infraBalanceStatus = 'loading';
      })
      .addCase(fetchInfraBalance.fulfilled, (state, action) => {
        state.infraBalanceStatus = 'succeeded';
        state.infraBalance = 0;
      })
      .addCase(fetchInfraBalance.rejected, state => {
        state.infraBalanceStatus = 'failed';
      })
      .addCase(fetchTestInfraBalance.pending, state => {
        state.infraBalanceStatus = 'loading';
      })
      .addCase(fetchTestInfraBalance.fulfilled, (state, action) => {
        state.infraBalanceStatus = 'succeeded';
        state.infraBalance = action.payload;
      })
      .addCase(fetchTestInfraBalance.rejected, state => {
        state.infraBalanceStatus = 'failed';
      })
      .addCase(fetchStakedInfra.pending, state => {
        state.totalStakedInfraStatus = 'loading';
      })
      .addCase(fetchStakedInfra.fulfilled, (state, action) => {
        state.totalStakedInfraStatus = 'succeeded';
        state.totalStakedInfra = action.payload.amount;
        state.totalStakedInfraTimestamp = action.payload.timestamp;
      })
      .addCase(fetchStakedInfra.rejected, state => {
        state.totalStakedInfraStatus = 'failed';
      })
      .addCase(fetchStakedRewards.pending, state => {
        state.totalStakedRewardsStatus = 'loading';
      })
      .addCase(fetchStakedRewards.fulfilled, (state, action) => {
        state.totalStakedRewardsStatus = 'succeeded';
        state.totalStakedRewards = action.payload;
      })
      .addCase(fetchStakedRewards.rejected, state => {
        state.totalStakedRewardsStatus = 'failed';
      })
      .addCase(checkHarvestStatus.pending, state => {
        state.canHarvestStakeStatus = 'loading';
      })
      .addCase(checkHarvestStatus.fulfilled, (state, action) => {
        state.canHarvestStakeStatus = 'succeeded';
        state.canHarvestStake = action.payload;
      })
      .addCase(checkHarvestStatus.rejected, state => {
        state.canHarvestStakeStatus = 'failed';
      })
      .addCase(checkEmergency.pending, state => {
        state.isEmergencyStatus = 'loading';
      })
      .addCase(checkEmergency.fulfilled, (state, action) => {
        state.isEmergencyStatus = 'succeeded';
        state.isEmergency = action.payload;
      })
      .addCase(checkEmergency.rejected, state => {
        state.isEmergencyStatus = 'loading';
      });
  },
});

export {
  fetchInfraBalance,
  fetchTestInfraBalance,
  fetchStakedInfra,
  fetchStakedRewards,
  checkHarvestStatus,
  checkEmergency,
};
export const { setTotalStakedInfra, resetStakeData } = StakingSlice.actions;
export default StakingSlice.reducer;
