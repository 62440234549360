import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { gql } from '@apollo/client';
import client, { apolloHeaders } from '../utils/apolloClient';
import { INFRAX_TEAM_ADDRESS } from '../utils/constants';

const fetchApps = createAsyncThunk(
  'apps/fetchApps',
  async (_, { rejectWithValue }) => {
    try {
      const { data, errors } = await client.query({
        query: gql`
          query GetApps {
            apps {
              averageExecutionTime
              description
              ethAddress
              id
              img {
                contentType
                id
                name
                path
                size
              }
              files {
                contentType
                id
                name
                path
                size
              }
              imgId
              lastModified
              meta
              name
              spec {
                BF16
                FP16
                FP32
                FP4
                FP64
                FP8
                FP80
                NF4
                TF32
                ram
                vram
              }
              publishDate
              state
              ts
            }
            apps {
              averageExecutionTime
              description
              ethAddress
              id
              img {
                contentType
                id
                name
                path
                size
              }
              files {
                contentType
                id
                name
                path
                size
              }
              imgId
              lastModified
              meta
              name
              publishDate
              state
              spec {
                BF16
                FP16
                FP32
                FP4
                FP64
                FP8
                FP80
                NF4
                TF32
                ram
                vram
              }
              ts
            }
          }
        `,
        context: apolloHeaders(INFRAX_TEAM_ADDRESS),
      });

      if (errors) {
        return rejectWithValue({ data: data.apps, errors });
      }

      return data.apps;
    } catch (error) {
      return rejectWithValue({ data: [], errors: [error.message] });
    }
  }
);

// NOTE: This is unused. Check gql.js for the correct mutation
// const createApp = createAsyncThunk(
//   'apps/createApp',
//   async (app, { rejectWithValue }) => {
//     try {
//       await client.mutate({
//         mutation: gql`
//           mutation CreateApp($app: AppInput!) {
//             createApp(app: $app) {
//               averageExecutionTime
//               description
//               ethAddress
//               id
//               img {
//                 contentType
//                 id
//                 name
//                 path
//                 size
//               }
//               imgId
//               lastModified
//               meta
//               name
//               publishDate
//               state
//               ts
//             }
//           }
//         `,
//         variables: {
//           app,
//         },
//         context: apolloHeaders(INFRAX_TEAM_ADDRESS),
//       });
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

const AppsSlice = createSlice({
  name: 'apps',
  initialState: {
    apps: [],
    fetchStatus: 'idle',
    error: null,
  },
  reducers: {
    updateApps: (state, action) => {
      state.apps = action.payload;
    },
    addApp: (state, action) => {
      state.apps.push(action.payload);
    },
    updateApp: (state, action) => {
      const { id } = action.payload;
      const existingApp = state.apps.find(app => app.id === id);
      if (existingApp) {
        Object.assign(existingApp, action.payload);
      }
    },
    removeApp: (state, action) => {
      state.apps = state.apps.filter(app => app.id !== action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchApps.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchApps.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.apps = action.payload;
        state.error = null;
      })
      .addCase(fetchApps.rejected, (state, action) => {
        state.status = 'failed';
        state.apps = action.payload.data;
        state.error = action.payload.errors;
      });
  },
});

export { fetchApps };
export const { addApp, removeApp, updateApps, updateApp } = AppsSlice.actions;

export default AppsSlice.reducer;
