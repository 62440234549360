import React from 'react';

function AppFormDescription({
  handleAppNameChange,
  handleAppDescriptionChange,
  handleAppIconChange,
  appName,
  appDescription,
  appIcon,
}) {
  return (
    <>
      <div className="network-form-row">
        <h3>App Details</h3>
        <a href="www.google.com">Infrax App Documentation</a>
      </div>
      <div className="network-form-row">
        <div className="form-cell">
          <label htmlFor="app-name">App Name*</label>
          <input
            type="text"
            id="app-name"
            name="app-name"
            required
            value={appName || ''}
            onChange={handleAppNameChange}
          />
        </div>
      </div>
      <div className="network-form-row">
        <div className="form-cell">
          <label htmlFor="app-description">App Description*</label>
          <textarea
            id="app-description"
            name="app-description"
            required
            value={appDescription || ''}
            onChange={handleAppDescriptionChange}
          />
        </div>
      </div>
      <div className="network-form-row">
        <div className="form-cell">
          <label htmlFor="app-icon">App Icon</label>
          {appIcon?.name && (
            <p>{`${appIcon?.name} already selected - pick a new one?`}</p>
          )}
          <input
            type="file"
            id="app-icon"
            name="app-icon"
            required
            onChange={handleAppIconChange}
          />
        </div>
      </div>
    </>
  );
}

export default AppFormDescription;
