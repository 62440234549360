import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppFormInputs from '../components/appFormInputs';

function AppForm() {
  const navigate = useNavigate();
  const { id } = useParams();

  function closeForm() {
    navigate('/apps');
  }

  return (
    <div
      id="apps-form-container"
      className="network-form-container network-content-container"
    >
      <div className="network-table-heading">
        <h2>{id ? 'Edit' : 'Create'} App</h2>
        <div>
          <img
            onClick={closeForm}
            id="close-app-form-button"
            src="/assets/icons/arrow_left_alt.svg"
            alt="close table"
          />
        </div>
      </div>
      <AppFormInputs appid={id} />
    </div>
  );
}

export default AppForm;
