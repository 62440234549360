import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { gql } from '@apollo/client';
import client from '../utils/apolloClient';

const fetchNodes = createAsyncThunk(
  'nodes/fetchNodes',
  async (_, { rejectWithValue }) => {
    try {
      const { data, errors } = await client.query({
        query: gql`
          query GetNodes {
            nodes {
              id
              location
              cpu
              gpu
              state
            }
          }
        `,
      });
      if (errors) {
        return rejectWithValue({ data: data.nodes, errors });
      }
      return data.nodes;
    } catch (error) {
      return rejectWithValue({ data: [], errors: [error.message] });
    }
  }
);

export const nodesSlice = createSlice({
  name: 'nodes',
  initialState: {
    nodes: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    addNode: (state, action) => {
      state.nodes.push(action.payload);
    },
    removeNode: (state, action) => {
      state.nodes = state.nodes.filter(node => node.id !== action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchNodes.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchNodes.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.nodes = action.payload;
      })
      .addCase(fetchNodes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export { fetchNodes };
export const { addNode, removeNode } = nodesSlice.actions;

export default nodesSlice.reducer;
