import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { SERVER } from './constants';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const httpLink = new HttpLink({
  uri: `${SERVER}/graphql`,
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});

function apolloHeaders(address) {
  const headers = { headers: { 'X-ETH-ADDRESS': address } };
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    headers.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return headers;
}

export default client;

export { apolloHeaders };
