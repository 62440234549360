import React, { useState } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import JobFormInputs from './jobFormInputs';
import JobAppDescription from './jobAppDescription';

function JobForm({ app = {} }) {
  const apps = useSelector(state => state.apps.apps);
  const [selectedApp, setSelectedApp] = useState(null);

  const selectStyles = {
    control: (styles, { isFocused, hover }) => ({
      ...styles,
      borderRadius: '0.375rem',
      border: '1px solid',
      borderColor: 'var(--color-border)',
      '&:hover': {
        borderColor: 'white',
        boxShadow: 'none',
      },
      padding: '0.375rem',
      backgroundColor: 'var(--color-gray)',
      color: 'white',
      fontSize: `${1}rem`,
      width: `${100}%`,
      lineHeight: `${1.25}rem`,
      fontWeight: 300,
      marginRight: 0,
      marginTop: `${0.625}rem`,
    }),
    input: styles => ({
      ...styles,
      color: 'var(--font-color-white-opacity-64)',
    }),
    singleValue: styles => ({
      ...styles,
      color: 'var(--font-color-white-opacity-64)',
    }),
    menu: styles => ({
      ...styles,
      backgroundColor: 'black',
      borderRadius: '0.375rem',
      border: '1px solid var(--color-border)',
    }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        color: isFocused ? 'black' : 'white',
        backgroundColor: isFocused ? 'white' : 'black',
      };
    },
  };

  function getAppOptions(apps) {
    return apps.map(app => {
      return { label: app.name, value: app.id };
    });
  }

  function getSelectedApp(selectedApp) {
    return apps.find(app => app.id === selectedApp.value);
  }

  return (
    <div
      id="jobs-form-container"
      className="network-form-container network-content-container infrax-jobs-form-drawer"
    >
      <div className="infrax-form-group-container">
        {app.name ? null : (
          <div className="network-form-row">
            <div className="form-cell" style={{ width: `${100}%` }}>
              <label htmlFor="app-search">Select an Infrax App</label>
              <Select
                id="app-search"
                options={getAppOptions(apps)}
                onChange={setSelectedApp}
                className="app-search"
                styles={selectStyles}
              />
            </div>
          </div>
        )}
        <div className="network-form-row">
          <div className="form-cell">
            {(app.name || selectedApp) && (
              <JobAppDescription
                app={app.name ? app : getSelectedApp(selectedApp)}
              />
            )}
          </div>
        </div>
        {(app.name || selectedApp) && (
          <JobFormInputs app={app.name ? app : getSelectedApp(selectedApp)} />
        )}
      </div>
    </div>
  );
}

export default JobForm;
