import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { addApp, updateApp } from '../slices/apps';
import AppFormDescription from './appFormDescription';
import AppFormFiles from './appFormFiles';
import AppFormSpec from './appFormSpec';
import AppInputBuilder from './appInputBuilder';

import { uploadFiles } from '../utils/file-utils';
import client, { apolloHeaders } from '../utils/apolloClient';
import { INFRAX_TEAM_ADDRESS } from '../utils/constants';
import { CREATE_APP, EDIT_APP } from '../utils/gql';

const initialInput = {
  name: '',
  type: 'string',
  required: false,
  description: '',
  kwarg: '',
  placeholder: '',
};

function AppFormInputs({ appid = '' }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appToEdit = useSelector(state =>
    state.apps.apps.find(app => app.id === appid)
  );
  const [AddApp, { error }] = useMutation(CREATE_APP, {
    client,
    context: apolloHeaders(INFRAX_TEAM_ADDRESS),
  });
  const [EditApp] = useMutation(EDIT_APP, {
    client,
    context: apolloHeaders(INFRAX_TEAM_ADDRESS),
  });

  const [metadata, setMetadata] = useState({
    inputs: [],
  });

  const [spec, setSpec] = useState({
    BF16: false,
    FP16: false,
    FP32: false,
    FP4: false,
    FP64: false,
    FP8: false,
    FP80: false,
    NF4: false,
    TF32: false,
    ram: 10,
    vram: 10,
  });
  const [files, setFiles] = useState([]);
  const [appIcon, setAppIcon] = useState(null);
  const [appDescription, setAppDescription] = useState('');
  const [appName, setAppName] = useState('');

  function setAppData(app) {
    setMetadata({ inputs: app.meta.kwargs });
    setAppDescription(app.description);
    setAppName(app.name);
    setAppIcon(app.img);
    setFiles(app.files);
  }

  useEffect(() => {
    if (appToEdit) {
      setAppData(appToEdit);
    }
  }, [appToEdit]);

  function handleAppInputChange(index, event) {
    const { name, value, type, checked } = event.target;
    const newInputs = [...metadata.inputs];
    newInputs[index] = {
      ...newInputs[index],
      [name]: type === 'checkbox' ? checked : value,
    };
    setMetadata({ ...metadata, inputs: newInputs });
  }

  function handleAppInputAdd() {
    setMetadata({ ...metadata, inputs: [...metadata.inputs, initialInput] });
  }

  function handleSpecChange(newSpec) {
    const specRam = Number(newSpec.ram);
    const specVram = Number(newSpec.vram);
    setSpec({
      ...newSpec,
      ram: specRam,
      vram: specVram,
    });
    console.log(spec, 'hhfjkdhfjkshdfkjsdhfkjdh');
  }

  function handleAppInputRemove(index) {
    const newInputs = metadata.inputs.filter((input, i) => i !== index);
    setMetadata({ ...metadata, inputs: newInputs });
  }

  function handleFilesChange(newFiles) {
    setFiles(newFiles);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    // NOTE: find a way to add a placeholder if appIcon is not provided
    const appIconFile = appIcon ? await uploadFiles([appIcon]) : null;
    const applicationFiles = await uploadFiles(files);
    const fileIds =
      applicationFiles.length && applicationFiles.map(file => file.id);

    const app = {
      ethAddress: INFRAX_TEAM_ADDRESS,
      name: appName,
      description: appDescription,
      fileIds,
      meta: { kwargs: metadata.inputs },
      spec: spec,
    };

    if (appIconFile) {
      const appIconId = appIconFile[0]?.id || '';
      app.imgId = appIconId;
    }

    try {
      const { data } = await AddApp({ variables: { app } });
      // Add app to the store
      if (data && data.createApp) {
        dispatch(addApp(data.createApp));
        navigate('/apps');
      } else {
        console.error('error creating app', error);
      }
    } catch (catchError) {
      console.error('error creating app', catchError);
    }
  }

  async function handleEdit(event) {
    event.preventDefault();
    // NOTE: find a way to add a placeholder if appIcon is not provided
    const isAppIconChanged = appIcon !== appToEdit?.img;
    const isAppDescriptionChanged = appDescription !== appToEdit?.description;
    const isAppNameChanged = appName !== appToEdit?.name;
    const isMetadataChanged =
      JSON.stringify(metadata.inputs) !==
      JSON.stringify(appToEdit?.meta.kwargs);
    const isFilesChanged =
      JSON.stringify(files) !== JSON.stringify(appToEdit?.files);
    const isSpecChanged =
      JSON.stringify(spec) !== JSON.stringify(appToEdit?.spec);

    const app = {
      id: appid,
      ethAddress: INFRAX_TEAM_ADDRESS,
      name: appToEdit.name,
      description: appToEdit.description,
      fileIds: appToEdit.files.map(file => file.id),
      meta: appToEdit.meta,
    };

    console.log(app);

    if (appIcon && isAppIconChanged) {
      const appIconFile = appIcon ? await uploadFiles([appIcon]) : null;
      if (appIconFile) {
        const appIconId = appIconFile[0]?.id || '';
        app.imgId = appIconId;
      }
    } else {
      app.imgId = appToEdit.imgId;
    }

    if (isFilesChanged) {
      const filesToUpload = files.filter(file => !file.id);
      const existingFiles = files.filter(file => file.id);
      const newFiles = await uploadFiles(filesToUpload);
      const fileIds = [
        ...existingFiles.map(file => file.id),
        ...newFiles.map(file => file.id),
      ];
      app.fileIds = fileIds;
    }

    if (isAppNameChanged) {
      app.name = appName;
    }

    if (isAppDescriptionChanged) {
      app.description = appDescription;
    }

    if (isMetadataChanged) {
      app.meta = { kwargs: metadata.inputs };
    }

    if (isSpecChanged) {
      app.spec = spec;
    }

    console.log(app);

    try {
      const { data } = await EditApp({ variables: { app } });
      // Add app to the store
      if (data && data.updateApp) {
        dispatch(updateApp(data.createApp));
        navigate('/apps');
      } else {
        console.error('error creating app', error);
      }
    } catch (catchError) {
      console.error('error creating app', catchError);
    }
  }

  function handleAppDescriptionChange(event) {
    setAppDescription(event.target.value);
  }

  function handleAppNameChange(event) {
    setAppName(event.target.value);
  }

  function handleAppIconChange(event) {
    setAppIcon(event.target.files[0]);
  }

  function validateForm() {
    if (!appName || !appDescription) {
      return false;
    }

    if (files.some(file => file.name === 'main.py') === false) {
      return false;
    }
    return true;
  }

  return (
    <div className="infrax-form-group-container">
      <form>
        <AppFormDescription
          handleAppNameChange={handleAppNameChange}
          handleAppDescriptionChange={handleAppDescriptionChange}
          handleAppIconChange={handleAppIconChange}
          appName={appName}
          appDescription={appDescription}
          appIcon={appIcon}
        />
        <AppInputBuilder
          inputs={metadata.inputs}
          handleInputChange={handleAppInputChange}
          handleRemoveInput={handleAppInputRemove}
          handleAddInput={handleAppInputAdd}
        />
        <AppFormFiles onFilesChange={handleFilesChange} appFiles={files} />
        <AppFormSpec handleChange={handleSpecChange} spec={spec} />
        <div className="network-form-row">
          <p className="required-fields">Fields marked with a * are required</p>
        </div>
        <div className="network-form-row">
          <div className="form-cell">
            {appid ? (
              <button
                type="submit"
                onClick={handleEdit}
                className="infrax-big-button"
                disabled={!validateForm()}
              >
                Update App
              </button>
            ) : (
              <button
                type="submit"
                onClick={handleSubmit}
                className="infrax-big-button"
                disabled={!validateForm()}
              >
                Submit App
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default AppFormInputs;
