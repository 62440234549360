import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { setMenuOpen } from '../slices/interface';

function Navigation() {
  const [networkActiveClass, setNetworkActiveClass] = React.useState('');
  const navMenuOpen = useSelector(state => state.interface.menuOpen);
  const location = useLocation();
  const dispatch = useDispatch();

  // Hack to fix weird network active state.
  React.useEffect(() => {
    if (location.pathname === '/' || location.pathname.includes('network')) {
      setNetworkActiveClass('icon-container nodes-icon active');
    } else {
      setNetworkActiveClass('icon-container nodes-icon');
    }
  }, [location.pathname]);

  return (
    <div
      className={`network-menu ${navMenuOpen ? 'nav-menu-open' : 'nav-menu-closed'}`}
    >
      <div className="menu-header-controls">
        <a className="infrax-logo" href="https://infrax.network/">
          <img
            src={`${navMenuOpen ? '/assets/icons/infrax_logo.svg' : '/assets/icons/infrax_logo_no_text.svg'}`}
            alt="logo"
          />
        </a>
        <img
          className="infrax-menu-button"
          src={`/assets/icons/${navMenuOpen ? 'infrax_system_menu_open' : 'infrax_system_menu_closed'}.svg`}
          alt="logo"
          onClick={() => dispatch(setMenuOpen())}
        />
      </div>
      <div className="icons-container">
        <NavLink
          to="/network"
          className={`${networkActiveClass} ${navMenuOpen ? 'network-menu-open' : ''}`}
          id="network-panel-button"
        >
          <img src="/assets/icons/infrax_system_nodes.svg" alt="" />
          <span>Nodes</span>
        </NavLink>
        <a
          href="https://dapp2.infrax.network/apps.html"
          className={`icon-container ${navMenuOpen ? 'network-menu-open' : ''}`}
          id="app-panel-button"
        >
          <img src="/assets/icons/infrax_system_apps.svg" alt="" />
          <span>Apps</span>
        </a>
        <a
          href="https://dapp2.infrax.network/job-feed.html"
          className={`icon-container ${navMenuOpen ? 'network-menu-open' : ''}`}
          id="job-panel-button"
        >
          <img src="/assets/icons/infrax_system_jobs.svg" alt="" />
          <span>Jobs</span>
        </a>
        <NavLink
          to="/staking"
          className={`icon-container ${navMenuOpen ? 'network-menu-open' : ''}`}
          id="staking-panel-button"
        >
          <img src="/assets/icons/infrax_system_staking.svg" alt="" />
          <span>Staking</span>
        </NavLink>
        <a
          href="https://dapp2.infrax.network/profile.html"
          className={`icon-container ${navMenuOpen ? 'network-menu-open' : ''}`}
          id="profile-panel-button"
        >
          <img src="/assets/icons/infrax_system_profile.svg" alt="" />
          <span>Profile</span>
        </a>
      </div>
      <div className="menu-footer-content">
        <div className="social-icons footer-icons">
          <div className="x-icon">
            <a href="https://x.com/InfraX_" target="_blank" rel="noreferrer">
              <img src="/assets/icons/infrax_system_x_logo.svg" alt="x.com" />
            </a>
          </div>
          <div className="dex-icon">
            <a
              href="https://www.dextools.io/app/en/ether/pair-explorer/0xfdcded6927a686b27254620752367bd6fe62fb0e"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/assets/icons/infrax_system_dex_logo.svg"
                alt="dextools.com"
              />
            </a>
          </div>
          <div className="telegram-icon">
            <a href="https://t.me/infraxai" target="_blank" rel="noreferrer">
              <img
                src="/assets/icons/infrax_system_telegram_logo.svg"
                alt="telegram"
              />
            </a>
          </div>
        </div>
        <div className="footer">
          <p>©2024 InfraX Network</p>
        </div>
      </div>
    </div>
  );
}

export default Navigation;
