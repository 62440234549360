import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { getEntry } from '../utils/geo-utils';
import NetworkTableRow from '../components/networkTableRow';

function NetworkDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const nodes = useSelector(state => state.nodes.nodes);

  function closeTable() {
    navigate('/network');
  }

  useEffect(() => {
    // double tap - make sure the tooltip is removed
    const tooltip = document.querySelector('.jvectormap-tip');
    if (tooltip && tooltip.parentNode) {
      tooltip.parentNode.removeChild(tooltip);
    }
  });

  return (
    <div
      id="table-container"
      className="network-table-container network-content-container"
    >
      <div className="network-table-heading">
        <div>
          <span id="country-name">{getEntry(id).name}</span>&nbsp;&nbsp;&nbsp;
          <span id="active-node-count">{nodes.length} - Active Nodes</span>
        </div>
        <div>
          <img
            onClick={closeTable}
            id="close-table-button"
            src="/assets/icons/arrow_left_alt.svg"
            alt="close table"
          />
        </div>
      </div>
      <div className="network-table-body table-body">
        <div className="table-row table-row-header">
          <div className="table-cell">
            <p>ID</p>
          </div>
          <div className="table-cell">
            <p>Status</p>
            <div className="table-cell-controls">
              <img src="/assets/icons/keyboard_arrow_up.svg" alt="arrow up" />
              <img
                src="/assets/icons/keyboard_arrow_down.svg"
                alt="arrow down"
              />
            </div>
          </div>
          <div className="table-cell">
            <p>App</p>
          </div>
          <div className="table-cell">
            <p>Primary Compute</p>
          </div>
          <div className="table-cell">
            <p>RAM / VRAM</p>
          </div>
        </div>
        <div id="table-populated" className="network-table-body-rows">
          {nodes.map(node => (
            <NetworkTableRow key={node.id} node={node} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default NetworkDetails;
