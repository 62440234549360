function getNodeSpec(node) {
  if (node.spec.cpu_only) {
    return node.spec.cpu;
  }
  return node.spec.gpu;
}

function getNodeSpecPlusMemory(node) {
  if (node.spec.cpu_only) {
    return `${node.spec.cpu} - ${node.spec.ram}GB RAM`;
  }
  return `${node.spec.gpu} - ${node.spec.vram}GB VRAM`;
}

export { getNodeSpec, getNodeSpecPlusMemory };
