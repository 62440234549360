import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ConnectWalletButton from './connectWalletButton';
import client from '../utils/apolloClient';
import { gql } from '@apollo/client';

function Header() {
  const ethBalance = useSelector(state => state.wallet.ethBalance);
  const connectedAccount = useSelector(state => state.wallet.connectedAccount);
  const headerTransparent = useSelector(
    state => state.interface.headerTransparent
  );

  const [devlogs, setDevlogs] = React.useState([]);

  useEffect(() => {
    fetchDevLog().then(data => setDevlogs(data));
  }, []);

  async function fetchDevLog() {
    const query = gql`
      query DevLog {
        devlogs {
          id
          ts
        }
      }
    `;
    const { data } = await client.query({ query });
    return data.devlogs;
  }

  return (
    <header className={`${headerTransparent ? 'header-transparent' : ''}`}>
      <div className="header-left-stats">
        <div className="network-status">
          <span>
            <div />
            <NavLink to="/devlog">Beta 1.{devlogs.length || 0}</NavLink>
          </span>
        </div>
        <div className="devlog">
          <img src="/assets/icons/infrax_system_list.svg" alt="Infrax Devlog" />
          <NavLink to="/devlog">Devlog</NavLink>
        </div>
        <div className="report-a-bug">
          <img src="/assets/icons/infrax_system_bug.svg" alt="Infrax Bug" />
          <a
            href="https://infrax.network/bug-report"
            rel="noreferrer"
            target="_blank"
          >
            Report a Bug
          </a>
        </div>
      </div>
      <div className="header-right-stats">
        {connectedAccount && (
          <div className="eth-balance">
            <span>Balance:</span>
            <img
              src="/assets/icons/infrax_system_ethereum.svg"
              alt="Etherium Icon"
            />
            <p>{ethBalance}</p>
          </div>
        )}
        <ConnectWalletButton />
      </div>
    </header>
  );
}

export default Header;
