import { gql } from '@apollo/client';

// Add App Mutation
const CREATE_APP = gql`
  mutation CreateApp($app: AppInput!) {
    createApp(app: $app) {
      averageExecutionTime
      description
      ethAddress
      id
      imgId
      lastModified
      meta
      name
      publishDate
      state
      spec {
        BF16
        FP16
        FP32
        FP4
        FP64
        FP8
        FP80
        NF4
        TF32
        ram
        vram
      }
      ts
    }
  }
`;

const EDIT_APP = gql`
  mutation UpdateApp($app: AppInput!) {
    updateApp(app: $app) {
      averageExecutionTime
      description
      ethAddress
      id
      imgId
      lastModified
      meta
      name
      publishDate
      state
      spec {
        BF16
        FP16
        FP32
        FP4
        FP64
        FP8
        FP80
        NF4
        TF32
        ram
        vram
      }
      ts
    }
  }
`;

export { CREATE_APP, EDIT_APP };
