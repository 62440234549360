import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { gql } from '@apollo/client';
import client, { apolloHeaders } from '../utils/apolloClient';
import { INFRAX_TEAM_ADDRESS } from '../utils/constants';

const testJobs = [];

const fetchJobs = createAsyncThunk(
  'jobs/fetchJobs',
  async (_, { rejectWithValue }) => {
    try {
      const { data, errors } = await client.query({
        query: gql`
          query MyQuery {
            jobs {
              ts
              timeToGiveUp
              state
              startTs
              meta
              lastModified
              id
              appId
              app {
                id
                name
              }
              result {
                error
                executionTime
                files {
                  name
                  path
                  size
                  ts
                  contentType
                  id
                }
                id
                jobId
                output
                success
                ts
              }
            }
          }
        `,
        context: apolloHeaders(INFRAX_TEAM_ADDRESS),
      });

      if (errors && errors.length > 0) {
        console.error('GraphQL errors:', errors);
        return rejectWithValue({ data: data.jobs, errors });
      }
      return data.jobs;
    } catch (error) {
      console.error('Exception caught:', error);
      return rejectWithValue({ data: [], errors: [error.message] });
    }
  }
);

const createJob = createAsyncThunk(
  'jobs/createJob',
  async ({ appId, ethAddress, fileIds, meta }, { rejectWithValue }) => {
    const variables = { job: { appId, ethAddress, fileIds, meta } };

    try {
      const { data, errors } = await client.mutate({
        mutation: gql`
          mutation MyMutation($job: JobInput!) {
            createJob(job: $job) {
              appId
              app {
                name
              }
              ethAddress
              id
              meta
              state
            }
          }
        `,
        variables,
        context: apolloHeaders(INFRAX_TEAM_ADDRESS),
      });

      if (errors) {
        return rejectWithValue({ data: data.createJob, errors });
      }
      return data.createJob;
    } catch (error) {
      return rejectWithValue({ data: null, errors: [error.message] });
    }
  }
);

const JobsSlice = createSlice({
  name: 'jobs',
  initialState: {
    jobs: testJobs,
  },
  reducers: {
    updateJobs: (state, action) => {
      state.jobs = action.payload;
    },
    addJob: (state, action) => {
      const job = { ...action.payload, id: state.jobs.length + 1 };
      state.jobs.push(job);
    },
    removeJob: (state, action) => {
      state.jobs = state.jobs.filter(job => job.id !== action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchJobs.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.jobs = [...action.payload];
        state.error = null;
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload.errors; // Capture the errors
        state.jobs = [...action.payload.data]; // Update the jobs state even if there are errors
      })
      .addCase(createJob.pending, state => {
        state.status = 'loading';
      })
      .addCase(createJob.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.jobs = [...state.jobs, action.payload];
      })
      .addCase(createJob.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export { fetchJobs, createJob };

export const { addJob, removeJob, updateJobs } = JobsSlice.actions;

export default JobsSlice.reducer;
