import React from 'react';

function AppFormSpec({ handleChange, spec }) {
  return (
    <>
      <div className="network-form-row" style={{ marginTop: 2 + 'rem' }}>
        <div className="network-form-cell">
          <h3>App Spec</h3>
          <p>The spec for your application.</p>
        </div>
      </div>
      <div className="network-form-row">
        <div className="form-cell">
          <label>
            <input
              type="checkbox"
              name="BF16"
              checked={spec.BF16}
              onChange={e => handleChange({ ...spec, BF16: e.target.checked })}
            />
            BF16
          </label>
          <label>
            <input
              type="checkbox"
              name="FP16"
              checked={spec.FP16}
              onChange={e => handleChange({ ...spec, FP16: e.target.checked })}
            />
            FP16
          </label>
          <label>
            <input
              type="checkbox"
              name="FP32"
              checked={spec.FP32}
              onChange={e => handleChange({ ...spec, FP32: e.target.checked })}
            />
            FP32
          </label>
          <label>
            <input
              type="checkbox"
              name="FP4"
              checked={spec.FP4}
              onChange={e => handleChange({ ...spec, FP4: e.target.checked })}
            />
            FP4
          </label>
          <label>
            <input
              type="checkbox"
              name="FP64"
              checked={spec.FP64}
              onChange={e => handleChange({ ...spec, FP64: e.target.checked })}
            />
            FP64
          </label>
          <label>
            <input
              type="checkbox"
              name="FP8"
              checked={spec.FP8}
              onChange={e => handleChange({ ...spec, FP8: e.target.checked })}
            />
            FP8
          </label>
          <label>
            <input
              type="checkbox"
              name="FP80"
              checked={spec.FP80}
              onChange={e => handleChange({ ...spec, FP80: e.target.checked })}
            />
            FP80
          </label>
          <label>
            <input
              type="checkbox"
              name="NF4"
              checked={spec.NF4}
              onChange={e => handleChange({ ...spec, NF4: e.target.checked })}
            />
            NF4
          </label>
          <label>
            <input
              type="checkbox"
              name="TF32"
              checked={spec.TF32}
              onChange={e => handleChange({ ...spec, TF32: e.target.checked })}
            />
            TF32
          </label>
        </div>
      </div>
      <div className={'network-form-row'}>
        <div className="form-cell">
          <label htmlFor="ram">RAM</label>
          <input
            type="number"
            id="ram"
            name="ram"
            value={spec.ram}
            onChange={e => handleChange({ ...spec, ram: e.target.value })}
          />
          <label htmlFor="vram" style={{ marginTop: 1 + 'rem' }}>
            VRAM
          </label>
          <input
            type="number"
            id="vram"
            name="vram"
            value={spec.vram}
            onChange={e => handleChange({ ...spec, vram: e.target.value })}
          />
        </div>
      </div>
    </>
  );
}

export default AppFormSpec;
