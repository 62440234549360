import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSDK } from '@metamask/sdk-react';
import { gql } from '@apollo/client';
import { disconnectWallet, connectWallet } from '../slices/wallet';
import { resetStakeData } from '../slices/staking';

import { setEthBalance } from '../slices/wallet';
import client from '../utils/apolloClient';
import { AUTH_PHRASE } from '../utils/constants';
import { getTruncatedWallet, getEthBalance } from '../utils/wallet-utils';

function ConnectWalletButton({ classOverwrite }) {
  const { sdk, connecting } = useSDK();
  const dispatch = useDispatch();
  const currentAccount = useSelector(state => state.wallet.connectedAccount);

  useEffect(() => {
    const loggedInWallet = currentAccount || localStorage.getItem('ethAddress');
    if (loggedInWallet) {
      dispatch(connectWallet(loggedInWallet));
    } else {
      dispatch(disconnectWallet());
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('ethAddress');
    }
  }, [currentAccount, dispatch]);

  function isMetamaskInstalled() {
    return typeof window.ethereum !== 'undefined';
  }

  async function authenticate(ethAddress, signature) {
    const query = gql`
      mutation Authenticate($ethAddress: String!, $signature: String!) {
        authenticate(auth: { ethAddress: $ethAddress, signature: $signature }) {
          accessToken
          refreshToken
        }
      }
    `;
    const response = await client.mutate({
      mutation: query,
      variables: { ethAddress, signature },
    });
    return response.data.authenticate;
  }

  async function loginWithMetamask() {
    if (!isMetamaskInstalled()) {
      alert('Please install Metamask');
      return;
    }
    const ethereum = sdk.getProvider();
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
    // const accounts = await sdk.connect();
    dispatch(connectWallet(accounts[0]));
    const ethBalance = await getEthBalance(accounts[0]);
    dispatch(setEthBalance(ethBalance));
    const signature = await ethereum.request({
      method: 'personal_sign',
      params: [AUTH_PHRASE, accounts[0]],
    });

    // TODO: Use this code to sign with Metamask
    // const signature = await sdk.connectAndSign({ msg: AUTH_PHRASE });

    const auth = await authenticate(accounts[0], signature);
    const { accessToken, refreshToken } = auth;
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('ethAddress', accounts[0]);
  }

  async function connectWalletButton() {
    if (currentAccount === null) {
      console.log('connecting wallet...');
      await loginWithMetamask();
    } else {
      console.log('disconnecting wallet...');
      dispatch(disconnectWallet());
      dispatch(resetStakeData());
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('ethAddress');
    }
  }

  return (
    <button
      className={`${classOverwrite || ''} infrax-system-button connect-wallet-button`}
      onClick={connectWalletButton}
      disabled={connecting}
    >
      {currentAccount !== null ? (
        <>
          <span>{getTruncatedWallet(currentAccount)}</span>
          <img src="/assets/icons/infrax_system_logout.svg" alt="logout" />
        </>
      ) : (
        <span>Connect Wallet</span>
      )}
    </button>
  );
}

export default ConnectWalletButton;
