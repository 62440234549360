import React from 'react';
import { useDrawer } from '../context/drawerContext';

function Drawer() {
  const {
    isOpen,
    currentComponent: Component,
    componentProps,
    closeDrawer,
    drawerTitle,
  } = useDrawer();

  return (
    <>
      <div
        className={`infrax-system-blur ${isOpen ? 'infrax-system-blur-on' : ''}`}
      ></div>
      <div
        className={`infrax-system-drawer ${isOpen ? 'infrax-system-drawer-open' : ''}`}
      >
        <div className="infrax-system-drawer-header">
          {drawerTitle && <h2>{drawerTitle}</h2>}
          <img
            src="/assets/icons/infrax_system_close.svg"
            alt="infrax-close"
            onClick={closeDrawer}
          />
        </div>
        <div className="infrax-system-drawer-content">
          {Component && <Component {...componentProps} />}
        </div>
      </div>
    </>
  );
}

export default Drawer;
