import { getNodeSpec } from '../utils/node-utils';

function NetworkTableRow({ node }) {
  return (
    <div className="table-row">
      <div className="table-cell">{node.id.slice(-8)}</div>
      <div className="table-cell">{node.status}</div>
      <div className="table-cell">{node.app || ' '}</div>
      <div className="table-cell">{getNodeSpec(node)}</div>
      <div className="table-cell">
        {node.spec.ram}Gb / {node.spec.vram}Gb
      </div>
    </div>
  );
}

export default NetworkTableRow;
