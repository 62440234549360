import React, { useEffect } from 'react';

function Apps() {
  useEffect(() => {
    window.location.href = 'https://dapp2.infrax.network/apps.html';
  });

  return (
    <div
      id="apps-container"
      className="network-table-container network-content-container"
    >
      <div className="infrax-subheader">
        <h1 className="infrax-subheader-title">
          InfraX&nbsp;<span>Apps</span>
        </h1>
      </div>
    </div>
  );
}

export default Apps;
