import React, { memo, useState } from 'react';
import _ from 'lodash';
import JobTableRow from './jobTableRow';

const deepEqual = (prevProps, nextProps) => {
  return _.isEqual(prevProps, nextProps);
};

const JobTable = memo(({ jobs }) => {
  const reversedJobs = [...jobs].reverse();
  const jobsPerPage = 10;
  const totalPages = Math.ceil(reversedJobs.length / jobsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * jobsPerPage;
  const endIndex = startIndex + jobsPerPage;
  const jobsToShow = reversedJobs.slice(startIndex, endIndex);

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="network-table-body table-body">
      <div id="job-table-populated" className="network-table-body-rows">
        {jobsToShow.map(job => (
          <JobTableRow key={job.id} job={job} />
        ))}
      </div>
      {jobs.length > jobsPerPage && (
        <div className="pagination">
          <button onClick={goToPreviousPage} disabled={currentPage === 1}>
            <img
              src="/assets/icons/keyboard_arrow_left.svg"
              alt="Infrax Devlog"
            />
          </button>
          <button
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
            style={{ marginLeft: 0.5 + 'rem' }}
          >
            <img
              src="/assets/icons/keyboard_arrow_right.svg"
              alt="Infrax Devlog"
            />
          </button>
        </div>
      )}
    </div>
  );
}, deepEqual);

export default JobTable;
