import React from 'react';

const Modal = ({ title, message, img, onApprove, onCancel, type }) => {
  return (
    <div className={'infrax-system-modal'}>
      <div className="infrax-system-modal-header">
        <img
          src="/assets/icons/infrax_system_close.svg"
          alt="infrax-close"
          onClick={onCancel}
        />
      </div>
      <img
        src={img || '/assets/icons/infrax_system_success.svg'}
        alt="infrax-success"
      />
      <h3>{title}</h3>
      <p>{message}</p>
      <div className={'infrax-button-row'}>
        <button className={'infrax-system-button'} onClick={onCancel}>
          Cancel
        </button>
        <button
          className={'infrax-system-button infrax-system-button-primary'}
          onClick={onApprove}
        >
          Approve
        </button>
      </div>
    </div>
  );
};

export default Modal;
