/* eslint-disable prettier/prettier */
import { configureStore } from '@reduxjs/toolkit';
import AppsSlice from './slices/apps';
import JobsSlice from './slices/jobs';
import NodesSlice from './slices/nodes';
import WalletSlice from './slices/wallet';
import InterfaceSlice from './slices/interface';
import StakingSlice from './slices/staking';


const store = configureStore({
  reducer: {
    apps: AppsSlice,
    jobs: JobsSlice,
    nodes: NodesSlice,
    wallet: WalletSlice,
    interface: InterfaceSlice,
    staking: StakingSlice,
  },
});

export default store;
