import React from 'react';

function JobFormInput({ input, handleInputChange }) {
  function getInputField(
    type,
    name,
    required,
    handleInputChange,
    placeholder,
    kwarg
  ) {
    switch (type) {
      case 'text':
        return (
          <input
            type="text"
            name={name}
            onChange={e => handleInputChange(name, e.target.value, type, kwarg)}
            required={required}
            placeholder={placeholder}
          />
        );
      case 'number':
        return (
          <input
            type="number"
            name={name}
            onChange={e => handleInputChange(name, e.target.value, type, kwarg)}
            required={required}
            placeholder={placeholder}
          />
        );
      case 'date':
        return (
          <input
            type="date"
            name={name}
            onChange={e => handleInputChange(name, e.target.value, type, kwarg)}
            required={required}
            placeholder={placeholder}
          />
        );
      case 'time':
        return (
          <input
            type="time"
            name={name}
            onChange={e => handleInputChange(name, e.target.value, type, kwarg)}
            required={required}
            placeholder={placeholder}
          />
        );
      case 'datetime-local':
        return (
          <input
            type="datetime-local"
            name={name}
            onChange={e => handleInputChange(name, e.target.value, type, kwarg)}
            required={required}
            placeholder={placeholder}
          />
        );
      case 'email':
        return (
          <input
            type="email"
            name={name}
            onChange={e => handleInputChange(name, e.target.value, type, kwarg)}
            required={required}
            placeholder={placeholder}
          />
        );
      case 'tel':
        return (
          <input
            type="tel"
            name={name}
            onChange={e => handleInputChange(name, e.target.value, type, kwarg)}
            required={required}
            placeholder={placeholder}
          />
        );
      case 'url':
        return (
          <input
            type="url"
            name={name}
            onChange={e => handleInputChange(name, e.target.value, type, kwarg)}
            required={required}
            placeholder={placeholder}
          />
        );
      case 'password':
        return (
          <input
            type="password"
            name={name}
            onChange={e => handleInputChange(name, e.target.value, type, kwarg)}
            required={required}
            placeholder={placeholder}
          />
        );
      case 'textarea':
        return (
          <textarea
            name={name}
            onChange={e => handleInputChange(name, e.target.value, type, kwarg)}
            required={required}
            placeholder={placeholder}
          />
        );
      case 'file':
        return (
          <input
            name={name}
            type="file"
            onChange={e =>
              handleInputChange(name, e.target.files[0], type, kwarg)
            }
            required={required}
          />
        );
      default:
        return (
          <input
            type="text"
            name={name}
            onChange={e => handleInputChange(name, e.target.value, type, kwarg)}
            required={required}
            placeholder={placeholder}
          />
        );
    }
  }
  return (
    <div className="network-form-row">
      <div className="form-cell">
        <label htmlFor="job-input-field">
          {input.name}
          {input.required ? '*' : ''}
        </label>
        {input.description && (
          <p className="job-input-field-description">{input.description}</p>
        )}
        {getInputField(
          input.type,
          input.name,
          input.required,
          handleInputChange,
          input.placeholder || '',
          input.kwarg || input.kwargg || ''
        )}
      </div>
    </div>
  );
}

export default JobFormInput;
