import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

function AppFormFiles({ onFilesChange, appFiles }) {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    setFiles(appFiles);
  }, [appFiles]);

  const onDrop = useCallback(
    acceptedFiles => {
      const updatedFiles = [...files, ...acceptedFiles];
      setFiles(updatedFiles);
      onFilesChange(updatedFiles);

      const mainFileExists = updatedFiles.some(file => file.name === 'main.py');
      if (!mainFileExists) {
        setError('main.py file is required');
      } else {
        setError(null);
      }
    },
    [files, onFilesChange]
  );

  function handleRemoveFile(fileToRemove) {
    const updatedFiles = files.filter(file => file !== fileToRemove);
    setFiles(updatedFiles);
    onFilesChange(updatedFiles);

    const mainFileExists = updatedFiles.some(file => file.name === 'main.py');
    if (!mainFileExists) {
      setError('main.py file is required');
    } else {
      setError(null);
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['.py', '.txt', '.json'],
  });

  return (
    <>
      <div className="network-form-row" style={{ marginTop: `${3}rem` }}>
        <h3>App Files</h3>
      </div>
      <div>
        <div
          {...getRootProps({ className: 'dropzone' })}
          style={{
            border: '2px dashed #cccccc',
            padding: '20px',
            textAlign: 'center',
          }}
        >
          <input {...getInputProps()} />
          <p>Drag & drop some files here, or click to select files</p>
        </div>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <ul>
          {files.map(file => (
            <li key={file.name}>
              {file.name}
              <button type="button" onClick={() => handleRemoveFile(file)}>
                Remove
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default AppFormFiles;
