// ModalProvider.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from '../components/modal';
import { setModalResult } from '../slices/interface';

const ModalProvider = () => {
  const dispatch = useDispatch();
  const { modalOpen, modalMessage, modalTitle, modalImg, modalType } =
    useSelector(state => state.interface);

  const handleApprove = () => {
    dispatch(setModalResult(true));
  };

  const handleCancel = () => {
    dispatch(setModalResult(false));
  };

  return (
    <>
      {modalOpen && (
        <>
          <div className={'infrax-system-blur infrax-system-blur-on'}></div>
          <Modal
            message={modalMessage}
            title={modalTitle}
            img={modalImg}
            type={modalType}
            onApprove={handleApprove}
            onCancel={handleCancel}
          />
        </>
      )}
    </>
  );
};

export default ModalProvider;
