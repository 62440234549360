import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className="not-found">
      <img src="/assets/images/infrax_spinner.gif" alt="infrax spinner" />
      <h1>404</h1>
      <p>
        Page not found. If you think you've found a bug please report it{' '}
        <a href="https://t.me/infraxai" target="_blank" rel="noreferrer">
          here
        </a>{' '}
        in our telegram :P
      </p>
      <Link to="/">Back to Network</Link>
      <p className="credits">
        This website (and service) was made by: bent grim hangar, bong martinis
        and eras fur quo
      </p>
    </div>
  );
}

export default NotFound;
