import { SERVER, INFRAX_TEAM_ADDRESS } from './constants';

function getFilePath(fileId) {
  return `${SERVER}/file/${fileId}`;
}

async function uploadFile(file) {
  return await uploadFiles([file]);
}

async function uploadFiles(files) {
  const formData = new FormData();
  files.forEach(file => {
    formData.append('file', file);
  });
  try {
    const response = await fetch(`${SERVER}/file`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-ETH-ADDRESS': INFRAX_TEAM_ADDRESS,
      },
      body: formData,
    });
    if (response.ok) {
      const responseJson = await response.json();
      return responseJson;
    }
    console.error('Failed to upload files');
    const responseJson = await response.json();
    console.error(responseJson);
  } catch (error) {
    console.error('Error occurred while uploading files:', error);
  }
}

export { getFilePath, uploadFiles, uploadFile };
