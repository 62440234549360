import React from 'react';

function InfoTile({ title, unit, icon, alt, data }) {
  return (
    <div className="infrax-info-tile">
      {icon && <img src={icon} alt={alt} />}
      <div>
        {title}
        <span>
          {unit}
          {data}
        </span>
      </div>
    </div>
  );
}

export default InfoTile;
