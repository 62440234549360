import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CONTRACT_ADDRESS, ALCHEMY_CONFIG } from '../utils/constants';
import { gql } from '@apollo/client';
import client, { apolloHeaders } from '../utils/apolloClient';
import { INFRAX_TEAM_ADDRESS } from '../utils/constants';

const fetchEthPrice = createAsyncThunk(
  'wallet/fetchEthPrice',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(
        'https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT'
      );
      const responseJson = await response.json();
      return responseJson.price;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchInfraPrice = createAsyncThunk(
  'wallet/fetchInfraPrice',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('https://api.infrax.network/prices');
      const responseJson = await response.json();
      return responseJson['infraX'].quote.USD.price.toFixed(2);
    } catch (error) {
      console.log('error', error.message);
      return rejectWithValue(error.message);
    }
  }
);

const fetchInfraBalance = createAsyncThunk(
  'wallet/fetchInfraBalance',
  async (_, { rejectWithValue, getState }) => {
    const state = getState();
    if (state.wallet.connected) {
      const account = state.wallet.connectedAccount;
      const baseURL = `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_CONFIG.apiKey}`;
      const data = JSON.stringify({
        jsonrpc: '2.0',
        method: 'alchemy_getTokenBalances',
        params: [`${account}`, [`${CONTRACT_ADDRESS}`]],
        id: 42,
      });

      const config = {
        method: 'post',
        url: baseURL,
        headers: {
          'Content-Type': 'application/json',
        },
        data,
      };

      axios(config)
        .then(function (response) {
          return Number(response.data.result.tokenBalances[0].tokenBalance);
        })
        .catch(function (error) {
          return rejectWithValue(error.message);
        });
    } else {
      return rejectWithValue('Wallet not connected');
    }
  }
);

const refreshToken = createAsyncThunk(
  'wallet/authenticate',
  async (_, { rejectWithValue }) => {
    try {
      const mutation = gql`
        mutation RefreshToken($refreshToken: String!) {
          refreshToken(refreshToken: $refreshToken) {
            accessToken
            refreshToken
          }
        }
      `;
      const { data, errors } = await client.mutate({
        mutation,
        variables: { refreshToken: localStorage.getItem('refreshToken') },
        constext: apolloHeaders(INFRAX_TEAM_ADDRESS),
      });

      localStorage.setItem('accessToken', data.refreshToken.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken.refreshToken);

      if (errors) {
        return rejectWithValue(errors);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const WalletSlice = createSlice({
  name: 'wallet',
  initialState: {
    connected: false,
    connectedAccount: '',
    reauthenticating: false,
    ethPrice: 0,
    ethBalance: 0.0,
    ethStatus: 'idle',
    infraStatus: 'idle',
    infraPriceStatus: 'idle',
    infraPrice: 0.0,
  },
  reducers: {
    disconnectWallet: state => {
      state.connected = false;
      state.ethBalance = 0.0;
      state.connectedAccount = null;
    },
    connectWallet: (state, action) => {
      state.connected = true;
      state.connectedAccount = action.payload;
    },
    setEthBalance: (state, action) => {
      state.ethBalance = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchEthPrice.pending, state => {
        state.ethStatus = 'loading';
      })
      .addCase(fetchEthPrice.fulfilled, (state, action) => {
        state.ethStatus = 'succeeded';
        state.ethPrice = action.payload;
      })
      .addCase(fetchEthPrice.rejected, state => {
        state.ethStatus = 'failed';
      })
      .addCase(refreshToken.pending, state => {
        state.reauthenticating = true;
      })
      .addCase(refreshToken.fulfilled, state => {
        state.reauthenticating = false;
      })
      .addCase(refreshToken.rejected, state => {
        state.reauthenticating = false;
      })
      .addCase(fetchInfraPrice.pending, state => {
        state.infraPriceStatus = 'loading';
      })
      .addCase(fetchInfraPrice.fulfilled, (state, action) => {
        state.infraPriceStatus = 'succeeded';
        state.infraPrice = action.payload;
      })
      .addCase(fetchInfraPrice.rejected, state => {
        state.infraPriceStatus = 'failed';
      });
  },
});

export { fetchEthPrice, fetchInfraBalance, refreshToken, fetchInfraPrice };
export const { connectWallet, disconnectWallet, setEthBalance } =
  WalletSlice.actions;
export default WalletSlice.reducer;
