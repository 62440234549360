import React from 'react';

function AppInputBuilder({
  inputs,
  handleInputChange,
  handleRemoveInput,
  handleAddInput,
}) {
  console.log('inputs', inputs);
  return (
    <>
      <div className="network-form-row" style={{ marginTop: `${3}rem` }}>
        <h3>App Inputs</h3>
      </div>
      {inputs &&
        inputs.map((input, index) => (
          <div key={index} style={{ marginBottom: '1rem' }}>
            <label>
              Name:
              <input
                type="text"
                name="name"
                value={input.name}
                onChange={e => handleInputChange(index, e)}
                required
              />
            </label>
            <label>
              Kwarg:
              <input
                type="text"
                name="kwarg"
                value={input.kwarg}
                onChange={e => handleInputChange(index, e)}
                required
              />
            </label>
            <label>
              Type:
              <select
                name="type"
                value={input.type}
                onChange={e => handleInputChange(index, e)}
              >
                <option value="input">Input</option>
                <option value="textarea">Textarea</option>
                <option value="number">Number</option>
                <option value="date">Date</option>
                <option value="time">Time</option>
                <option value="checkbox">Checkbox</option>
                <option value="url">URL</option>
                <option value="file">File</option>
              </select>
            </label>
            <label>
              Required:
              <input
                type="checkbox"
                name="required"
                checked={input.required}
                onChange={e => handleInputChange(index, e)}
              />
            </label>
            <label>
              Description:
              <input
                type="text"
                name="description"
                value={input.description}
                onChange={e => handleInputChange(index, e)}
              />
            </label>
            {input.type !== 'checkbox' && input.type !== 'file' && (
              <label>
                Placeholder:
                <input
                  type="text"
                  name="placeholder"
                  value={input.placeholder}
                  onChange={e => handleInputChange(index, e)}
                />
              </label>
            )}
            <button type="button" onClick={() => handleRemoveInput(index)}>
              Remove
            </button>
          </div>
        ))}
      <button type="button" onClick={handleAddInput}>
        Add Field
      </button>
    </>
  );
}

export default AppInputBuilder;
