import React, { useState, useEffect } from 'react';
import Markdown from 'react-markdown';
import client from '../utils/apolloClient';
import { gql } from '@apollo/client';

async function fetchDevLog() {
  const query = gql`
    query DevLog {
      devlogs {
        id
        ts
        message
      }
    }
  `;
  const { data } = await client.query({ query });
  return data.devlogs;
}

function Devlog() {
  const [loading, setLoading] = useState(true);
  const [devlogs, setDevlogs] = React.useState([]);

  useEffect(() => {
    fetchDevLog().then(data => setDevlogs(data));
    setLoading(false);
  }, []);

  function formatDate(ts) {
    console.log(ts);
    const date = new Date(ts * 1000); // convert from seconds to milliseconds
    return date.toLocaleDateString();
  }

  return (
    <div className="network-table-container network-content-container">
      <h1>Devlog</h1>
      <div>
        {loading && <div>Loading...</div>}
        {devlogs.length === 0 && !loading && <div>No devlogs yet!</div>}
        {devlogs.reverse().map(devlog => (
          <div className={'devlog-entry'} key={devlog.id}>
            <h2>{formatDate(devlog.ts)}</h2>
            <Markdown>{devlog.message}</Markdown>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Devlog;
