import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSDK } from '@metamask/sdk-react';
import Web3, { utils } from 'web3';
import {
  STAKING_CONTRACT,
  COIN_CONTRACT,
  STAKING_CONTRACT_ABI,
} from '../utils/constants';

import CoinContractAbi from '../abi_coin.json';
import StakingContractAbi from '../abi_staking.json';

import InfoTile from '../components/infoTile';

import { showModal, hideModal } from '../slices/interface';
import {
  fetchTestInfraBalance,
  checkEmergency,
  fetchStakedInfra,
  fetchStakedRewards,
  checkHarvestStatus,
} from '../slices/staking';
import store from '../store';

function translateTimeRemaining(time) {
  const days = Math.floor(time / (24 * 60 * 60));
  const hours = Math.floor((time % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((time % (60 * 60)) / 60);
  const seconds = Math.floor(time % 60);
  return `${days}:${hours}:${minutes}:${seconds}`;
}

function Staking() {
  const { sdk, connecting } = useSDK();
  const dispatch = useDispatch();
  const wallet = useSelector(state => state.wallet);
  const staking = useSelector(state => state.staking);
  const [amountToStake, setAmountToStake] = useState(0);
  const [stakeInterval, setStakeInterval] = useState(0);

  useEffect(() => {
    if (wallet.connectedAccount) {
      // dispatch(fetchInfraBalance());
      dispatch(fetchTestInfraBalance());
      dispatch(fetchStakedInfra());
      dispatch(fetchStakedRewards());
      dispatch(checkHarvestStatus());
      dispatch(checkEmergency());
    }
    const interval = setInterval(() => {
      // dispatch(fetchInfraBalance());
      dispatch(fetchTestInfraBalance());
      dispatch(fetchStakedInfra());
      dispatch(fetchStakedRewards());
      dispatch(checkHarvestStatus());
    }, 30000);

    const stakeTimeInterval = setInterval(() => {
      if (staking.totalStakedInfra > 0) {
        const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
        const stakeTime = 30 * 24 * 60 * 60; // 30 days in seconds
        const timeLeftOnStake =
          staking.totalStakedInfraTimestamp + stakeTime - currentTime;
        setStakeInterval(timeLeftOnStake);
      }
    }, 5000);
    return () => clearInterval(interval, stakeTimeInterval);
  }, [
    dispatch,
    wallet.connectedAccount,
    staking.totalStakedInfra,
    staking.totalStakedInfraTimestamp,
  ]);

  function navigateToBuy() {
    window.open(
      'https://app.uniswap.org/explore/tokens/ethereum/0xe9eccde9d26fcbb5e93f536cfc4510a7f46274f8',
      '_blank'
    );
  }

  function navigateToDocs() {
    window.open(
      'https://dapp.infrax.network/staking_documentation.pdf',
      '_blank'
    );
  }

  function setPercentStake(percent) {
    const balance = staking.infraBalance;
    const trueBalance = Math.floor((balance * 100 * percent) / 100) / 100;
    setAmountToStake(trueBalance);
  }

  function getStakePlusRewards() {
    const total = staking.totalStakedInfra + staking.totalStakedRewards;
    const rounded = Math.round(total * 1000) / 1000;
    return rounded;
  }

  async function stake() {
    const amount = utils.toWei(amountToStake, 'gwei');

    if (typeof window.ethereum !== 'undefined') {
      const ethereum = sdk.getProvider();
      const accounts = await ethereum.request({
        method: 'eth_requestAccounts',
      });
      const web3Instance = new Web3(ethereum);

      const contract = new web3Instance.eth.Contract(
        CoinContractAbi,
        COIN_CONTRACT
      );

      const stakingContract = new web3Instance.eth.Contract(
        StakingContractAbi.abi,
        STAKING_CONTRACT
      );

      dispatch(
        showModal({
          message: `Caution: You are about to stake ${amountToStake} $INFRA? After you click APPROVE - you will be asked to confirm this transaction. You won't be able to withdraw your stake before a month.`,
          title: 'Stake Confirmation',
          img: '/assets/icons/infrax_system_success.svg',
        })
      );

      const userResponse = await new Promise((resolve, reject) => {
        const unsubscribe = store.subscribe(() => {
          const modalResult = store.getState().interface.modalResult;
          if (modalResult !== null) {
            resolve(modalResult);
            unsubscribe();
          }
        });
      });

      if (userResponse) {
        const tokenApproved = await contract.methods
          .approve(STAKING_CONTRACT, amount)
          .send({ from: accounts[0] });
        console.log(tokenApproved);
        const stakeApproved = await stakingContract.methods
          .stake(amount)
          .send({ from: accounts[0] });
        console.log('STAKE APPROVED', stakeApproved);
        setAmountToStake(0);
      } else {
        dispatch(hideModal());
        return;
      }
      dispatch(hideModal());
    } else {
      return;
    }
  }

  async function harvest() {
    if (typeof window.ethereum !== 'undefined') {
      const ethereum = sdk.getProvider();
      const accounts = await ethereum.request({
        method: 'eth_requestAccounts',
      });
      const web3Instance = new Web3(ethereum);

      const stakingContract = new web3Instance.eth.Contract(
        StakingContractAbi.abi,
        STAKING_CONTRACT
      );

      dispatch(
        showModal({
          messsage: `Harvesting from stake of ${staking.totalStakedInfra} $INFRA.`,
          title: 'Harvest Confirmation',
          img: '/assets/icons/infrax_system_success.svg',
        })
      );

      const userResponse = await new Promise((resolve, reject) => {
        const unsubscribe = store.subscribe(() => {
          const modalResult = store.getState().interface.modalResult;
          if (modalResult !== null) {
            resolve(modalResult);
            unsubscribe();
          }
        });
      });

      if (userResponse) {
        const harvestApproved = await stakingContract.methods
          .harvest()
          .send({ from: accounts[0] });
        console.log('HARVEST APPROVED', harvestApproved);
      } else {
        dispatch(hideModal());
        return;
      }
      dispatch(hideModal());
    } else {
      return;
    }
  }

  async function withdraw() {
    if (typeof window.ethereum !== 'undefined') {
      const ethereum = sdk.getProvider();
      const accounts = await ethereum.request({
        method: 'eth_requestAccounts',
      });
      const web3Instance = new Web3(ethereum);

      const stakingContract = new web3Instance.eth.Contract(
        StakingContractAbi.abi,
        STAKING_CONTRACT
      );

      dispatch(
        showModal({
          message: `Caution: You are about to withdraw ${staking.totalStakedInfra} $INFRA? After you click APPROVE - you will withdraw your infra stake. If you can harvest rewards, and you withdraw before you harvest you WILL LOSE your potential rewards. This action cannot be reversed.`,
          title: 'Withdraw Confirmation',
          img: '/assets/icons/infrax_system_success.svg',
        })
      );

      const userResponse = await new Promise((resolve, reject) => {
        const unsubscribe = store.subscribe(() => {
          const modalResult = store.getState().interface.modalResult;
          if (modalResult !== null) {
            resolve(modalResult);
            unsubscribe();
          }
        });
      });

      if (userResponse) {
        const stakeWithdrawn = await stakingContract.methods
          .withdraw()
          .send({ from: accounts[0] });
        console.log('STAKE WITHDRAWN', stakeWithdrawn);
      } else {
        dispatch(hideModal());
        return;
      }
      dispatch(hideModal());
    } else {
      return;
    }
  }

  async function emergency() {
    if (typeof window.ethereum !== 'undefined') {
      const ethereum = sdk.getProvider();
      const accounts = await ethereum.request({
        method: 'eth_requestAccounts',
      });
      const web3Instance = new Web3(ethereum);

      const stakingContract = new web3Instance.eth.Contract(
        StakingContractAbi.abi,
        STAKING_CONTRACT
      );

      dispatch(
        showModal({
          message: `There are no funds left in the contract withdraw: ${staking.totalStakedInfra} Staked $INFRA?.`,
          title: 'Emergency Withdrawal Confirmation',
          img: '/assets/icons/infrax_system_success.svg',
        })
      );

      const userResponse = await new Promise((resolve, reject) => {
        const unsubscribe = store.subscribe(() => {
          const modalResult = store.getState().interface.modalResult;
          if (modalResult !== null) {
            resolve(modalResult);
            unsubscribe();
          }
        });
      });

      if (userResponse) {
        const stakeApproved = await stakingContract.methods
          .emergencyWithdraw()
          .send({ from: accounts[0] });
        console.log('EMERGENCY WITHDRAW', stakeApproved);
      } else {
        dispatch(hideModal());
        return;
      }
      dispatch(hideModal());
    } else {
      return;
    }
  }

  return (
    <div
      id="staking-container"
      className="network-table-container network-content-container"
    >
      <div className="infrax-subheader">
        <h1 className="infrax-subheader-title">
          InfraX&nbsp;<span>Staking</span>
        </h1>
        <h2>
          <span>Total Staked</span> $INFRA: {staking.totalStakedInfra}
        </h2>
      </div>
      <div className="infrax-staking-container-body">
        <div className="infrax-staking-top-tiles">
          <InfoTile
            title="Lock Time"
            unit=""
            data={'1 Month'}
            className={'overide'}
          />
          <InfoTile title="Max % Amount" unit="" data={'100%'} />
          <InfoTile title="APY" unit="" data={'32.5% - 10%*'} />
          <div className="infrax-staking-docs">
            <button
              onClick={navigateToDocs}
              className="infrax-system-button infrax-system-button-primary"
            >
              Docs
            </button>
          </div>
        </div>
        <div className="infrax-staking-bottom-tiles">
          <div className={'infrax-action-tile infrax-info-tile'}>
            <div className={'left-region'}>
              <img
                src={'/assets/icons/infrax_logo_no_text.svg'}
                alt={'infrax-logo'}
              />
              <div>
                My <strong>$INFRA</strong> Balance
                <span>
                  {/* {staking.infraBalance} */}
                  {Number(staking.infraBalance).toFixed(2)}
                  <span>
                    {' '}
                    / $
                    {(
                      (staking.infraBalance || 0) * Number(wallet.infraPrice)
                    ).toFixed(2)}
                  </span>
                </span>
              </div>
            </div>
            <button onClick={navigateToBuy} className={'infrax-system-button'}>
              Buy $INFRA
            </button>
          </div>
          <div
            className={
              'infrax-action-tile infrax-info-tile infrax-staking-region'
            }
          >
            <div className={'infrax-fake-input'}>
              <div>
                <p>Staking Amount:</p>
                <input
                  placeholder={0}
                  className={'infrax-input'}
                  value={amountToStake}
                  onChange={({ target }) => setAmountToStake(target.value)}
                />
              </div>
              <div className={'infrax-fake-input-button-bar'}>
                <button onClick={() => setPercentStake(25)}>25%</button>
                <button onClick={() => setPercentStake(50)}>50%</button>
                <button onClick={() => setPercentStake(75)}>75%</button>
                <button
                  onClick={() => setPercentStake(100)}
                  className={'max-button'}
                >
                  Max
                </button>
              </div>
            </div>
            <div className={'infrax-stake-bottom-button'}>
              <button
                className={'infrax-system-button infrax-system-button-primary'}
                onClick={() => stake()}
                disabled={
                  !(wallet.connectedAccount && staking.infraBalance > 0)
                }
              >
                Stake Now
              </button>
            </div>
          </div>
          <div className={'infrax-action-tile infrax-info-tile'}>
            <div className="left-region">
              <img
                src={'/assets/icons/infrax_logo_no_text.svg'}
                alt={'infrax-logo'}
              />
              <div className={'infrax-system-timer'}>
                My Staked $INFRA{' '}
                {staking.totalStakedInfra > 0 && stakeInterval > 0 && (
                  <strong>
                    Harvest available in:{' '}
                    {translateTimeRemaining(stakeInterval)}{' '}
                  </strong>
                )}
                <span>
                  {getStakePlusRewards() || 0}{' '}
                  <span> / {staking.totalStakedInfra || 0}</span>
                </span>
              </div>
            </div>
            <div>
              <button
                onClick={withdraw}
                disabled={
                  !(staking.canHarvestStake && staking.totalStakedInfra > 0)
                }
                className={'infrax-system-button'}
                style={{ marginRight: '1rem' }}
              >
                Withdraw Stake
              </button>
              {staking.isEmergency && staking.totalStakedInfra > 0 && (
                <button
                  onClick={emergency}
                  disabled={!(staking.totalStakedInfra > 0)}
                  className={'infrax-system-button'}
                  style={{ marginRight: '1rem' }}
                >
                  Emergency Withdrawal
                </button>
              )}
              <button
                onClick={harvest}
                disabled={
                  !(staking.canHarvestStake && staking.totalStakedInfra > 0)
                }
                className={'infrax-system-button'}
              >
                Harvest $INFRA
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Staking;
