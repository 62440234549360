import { infraxTeamAddress } from '../utils/app-utils';
import { getFilePath } from '../utils/file-utils';

function JobAppDescription({ app }) {
  function addressOrInfraxTeam(ethAddress) {
    return infraxTeamAddress(ethAddress) ? 'Infrax Team' : ethAddress;
  }

  return (
    <div className="app-description">
      {app?.img?.path && <img src={app.img.path} alt={app.name} />}
      {app.imgId && <img src={getFilePath(app.img.id)} alt={app.img.name} />}
      <div className="app-description-label">
        <label>{app.name}</label>
        <p>{app.description}</p>
        <p>
          <strong>Created By:</strong> {addressOrInfraxTeam(app.ethAddress)}
        </p>
      </div>
    </div>
  );
}

export default JobAppDescription;
