import React, { useEffect } from 'react';

function Profile() {
  useEffect(() => {
    window.location.href = 'https://dapp2.infrax.network/profile.html';
  });

  return (
    <div
      id="profile-container"
      className="network-table-container network-content-container"
    >
      <div className="infrax-subheader">
        <h1 className="infrax-subheader-title">
          InfraX&nbsp;<span>Profile</span>
        </h1>
      </div>
    </div>
  );
}

export default Profile;
