import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import JobFormInput from './jobFormInput';

import { useDrawer } from '../context/drawerContext';
import { createJob } from '../slices/jobs';
import { prepareJobPayload } from '../utils/job-utils';

function JobFormInputs({ app }) {
  const [formData, setFormData] = useState({});
  const currentAccount = useSelector(state => state.wallet.connectedAccount);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { closeDrawer } = useDrawer();

  function handleInputChange(id, value, type, kwarg) {
    setFormData({
      ...formData,
      [id]: {
        value,
        type,
        kwarg,
      },
    });
  }

  async function handleJobSubmit(event) {
    event.preventDefault();
    event.target.reset();
    const job = await prepareJobPayload(formData, app.id, currentAccount);
    setFormData({});
    dispatch(createJob(job));
    closeDrawer();
    navigate('/jobs');
  }

  function validateRequiredInputs() {
    if (app.meta?.kwargs) {
      const requiredInputs = app.meta.kwargs.filter(input => input.required);
      const missingInputs = requiredInputs.filter(
        input => !formData[input.name]
      );
      return missingInputs.length === 0;
    }
    return true;
  }

  return (
    <form onSubmit={handleJobSubmit}>
      <div className="job-form-inputs">
        <div className="network-form-row" style={{ marginBottom: `${10}px` }}>
          <h3>App Inputs</h3>
        </div>
        <JobFormInput
          input={{ name: 'Job Title', required: true }}
          handleInputChange={handleInputChange}
        />
        {app.meta?.kwargs?.map((input, index) => (
          <JobFormInput
            key={index}
            input={input}
            handleInputChange={handleInputChange}
          />
        ))}
        <div className="network-form-row">
          {app.meta?.kwargs?.length > 0 ? (
            <p className="required-fields">
              Fields marked with a * are required
            </p>
          ) : (
            <p className="required-fields">No inputs required for this job</p>
          )}
        </div>
      </div>
      <div className="network-form-row job-form-submit infrax-drawer-submit"></div>
    </form>
  );
}

export default JobFormInputs;
