import React from 'react';

function EmptyState({ title, description, action, actionText }) {
  return (
    <div className="network-empty-state" style={{ marginBottom: `${5}rem` }}>
      <img src="/assets/icons/infrax_logo_no_text.svg" alt="" />
      <h2>{title}</h2>
      <p>{description}</p>
      {action && (
        <button
          onClick={action}
          className="infrax-system-button infrax-system-button-primary"
        >
          {actionText}
        </button>
      )}
    </div>
  );
}

export default EmptyState;
