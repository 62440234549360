import React, { createContext, useContext, useState } from 'react';

const DrawerContext = createContext();

function useDrawer() {
  return useContext(DrawerContext);
}

function DrawerProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentComponent, setCurrentComponent] = useState(null);
  const [drawerTitle, setDrawerTitle] = useState('');
  const [componentProps, setComponentProps] = useState({});

  function openDrawer(Component, props = {}, title = '') {
    setCurrentComponent(() => Component);
    setComponentProps(props);
    setDrawerTitle(title);
    setIsOpen(true);
  }

  function closeDrawer() {
    setIsOpen(false);
  }

  return (
    <DrawerContext.Provider
      value={{
        isOpen,
        currentComponent,
        componentProps,
        openDrawer,
        closeDrawer,
        drawerTitle,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
}

export { useDrawer, DrawerProvider };
